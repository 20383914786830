import React, { useEffect, useState, createContext, useContext } from 'react';
import firebase from 'firebase';
import * as _ from 'lodash'

var config = {
    apiKey: process.env.REACT_APP_APIKEY,
    authDomain: process.env.REACT_APP_AUTHDOMAIN,
    databaseURL: process.env.REACT_APP_DATABASEURL,
    projectId: process.env.REACT_APP_PROJECTID,
    storageBucket: process.env.REACT_APP_STORAGEBUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
    appId: process.env.REACT_APP_APPID,
    measurementId: process.env.REACT_APP_MEASUREMENTID
};

export const FirebaseApp = firebase.initializeApp(config);
export const Firestore = firebase.firestore(FirebaseApp);
export const Database = firebase.database(FirebaseApp);
export const Auth = firebase.auth(FirebaseApp);
export const Storage = firebase.storage(FirebaseApp);
export const Analytics = firebase.analytics(FirebaseApp);
export const Functions = FirebaseApp.functions("europe-west1")

export const WithFirebase = (props) => {
    return (
        <div>
            { props.children }
        </div>
    )
}

export const Authentication = {
    WhenLoggedIn: ({ children = null }) => {

        const { isLoggedIn, isLoading } = useContext(AuthenticationContext)
    
        if(isLoading || !isLoggedIn) {
            return (<div></div>)
        }
    
        return (
            children
        )
    },
    WhenLoggedOut: ({ children = null }) => {

        const { isLoggedIn, isLoading } = useContext(AuthenticationContext)
    
        if(isLoading || isLoggedIn) {
            return (<div></div>)
        }
    
        return (
            children
        )
    },
    GetCurrentUserId: () => {

        const [uid, setUid] = useState(null)
        const [user, setUser] = useState({})
        const [isLoading, setIsLoading] = useState(true)

        useEffect(() => {
            return Auth.onAuthStateChanged((user) => {
                if(user !== null) {
                    setUid(user.uid)
                    setUser(user)
                } else {
                    setUid(null)
                    setUser({})
                }
                setIsLoading(false)
            })
        }, [])

        return { uid, user, isLoading }
    },
    WithAuthentication: ({ children }) => {
        const { uid, user, isLoading } = Authentication.GetCurrentUserId()

        return (
            <AuthenticationContext.Provider value={{ uid, user, isLoggedIn: !_.isEmpty(user), isLoading }}>
                { children }
            </AuthenticationContext.Provider>
        )
    }
}

export const AuthenticationContext = createContext({ uid: "", isLoggedIn: false })