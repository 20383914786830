import React, { useState, useEffect } from 'react';
import {
    useParams
} from "react-router-dom";
import { Card } from '../../components/general/Card';
import { Database } from '../../firebase';

export const ThankYouPage = (props) => {

    const [isDoneGenerating, setIsDoneGenerating] = useState(false)

    const urlParams = new URLSearchParams(window.location.search);

    const sourceId = urlParams.get('source')
    const clientSecret = urlParams.get('client_secret')

    useEffect(() => {
        const ref = Database.ref("transactions/" + sourceId + "/pdf")

        ref.on("value", (snapshot) => {
            const value = snapshot.val() ? snapshot.val() : {}
            const { isGenerated = false } = value
            setIsDoneGenerating(isGenerated)
        })

        return () => {
            ref.off();
            console.log("disconnected")
        }

    }, [sourceId + clientSecret]);

    const onDownloadTickets = () => {
        window.open("/api/downloadTickets" + window.location.search, "_blank")
    }

    return (
        <Card.Content>
            Gelukt! Tickets downloaden...
        </Card.Content>
    );
}