import React from 'react';
import _ from 'lodash';
import { useField } from 'formik'

export const TextElement = ({ tooltip = false, label = false, name = "", placeholder="", className="", ...rest}) => {

    const [field, meta, helpers] = useField({ name });

    console.log("errors", meta)

    return (
        
        <div className="block w-full text-sm">
            { label && <label className="font-semibold">{ label }</label> }
            <input {...field} placeholder={placeholder} className="appearance-none block w-full mt-2 px-4 py-3 border shadow-sm border-gray-300 rounded-md placeholder-gray-400 font-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
            {/* <input {...field} className={"appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400  " + (meta.error && meta.touched) ? "" : ""} /> */}
            {
                tooltip && <span className="inline-block p-1 w-full text-gray-500 text-xs">{tooltip}</span>
            }
            {
                meta.touched && meta.error && <div className={"inline-block p-1 text-red-500 text-xs"}> {meta.error} </div>
            }
        </div>
    )
}
