import { useQuery } from "react-query"
import { Firestore } from "../firebase"

export const Queries = {
    GetUserProfile: (uid = "") => {
        return useQuery(["/users/" + uid, { uid }], (_, { uid }) => getUser(uid))
    }
}

const getUser = async (id = "") => {
    console.log("call", id)
    const user = await Firestore.collection("users").doc(id).get()
    console.log("exists?", user.exists)
    return {
        id: user.id,
        ...user.data()
    }
}