import React, { useState, useEffect, useContext } from 'react';
import { getCentsToEurosString } from '../../utils';
import { WidgetContext } from '../Widget';

export const ItemSelection = ({ className = "" }) => {

    const { items = [], selectedItems = [], setSelectedItems } = useContext(WidgetContext)

    const soldOut = items.length === 0;

    const getOptionsForLine = (index = 0) => {
        return items.filter((item) => {
            let returned = true;

            selectedItems.forEach((sItem, _index) => {
                if (_index === index || _index >= selectedItems.length) {
                    return;
                }

                if (item.id === sItem.id) {
                    returned = false;
                }
            })
            return returned;
        })
    }

    const getLines = () => {
        return selectedItems.map((item, index) => {
            const options = getOptionsForLine(index).map((item) => {
                const { id, name, price } = item
                return {
                    label: name + " - " + getCentsToEurosString(price),
                    value: id
                }
            });

            return <ItemSelectionLine index={index} key={"items." + index} options={options} />
        })
    }

    const lines = soldOut ? <p>Er zijn momenteel geen tickets te koop voor dit evenement.</p> : getLines();

    return (
        <div className={className}>
            {lines}
            {/* <ItemCardButtons handleLineChange={incrementLine} /> */}
            {/* <ItemCardTable /> */}
        </div>
    )
}

const ItemCardButtons = ({ handleLineChange = () => { } }) => {

    const { items = [], selectedItems = [] } = useContext(WidgetContext)

    return (
        <div style={{
            justifyContent: "space-between",
            display: "flex"
        }}>
            {selectedItems.length < items.length && <button className="btn btn-link" onClick={event => {
                event.preventDefault();
                handleLineChange(1);
            }}>Regel Toevoegen</button>}
            {selectedItems.length > 1 && <button className="btn btn-link" onClick={event => {
                event.preventDefault();
                handleLineChange(-1);
            }}>Regel Verwijderen</button>}
        </div>
    )
}

const ItemSelectionLine = ({ className = "", index = 0, options = [] }) => {

    const { setSelectedItems, selectedItems } = useContext(WidgetContext)

    const [selectedItemId, setSelectedItemId] = useState(selectedItems[index].id)
    const [amount, setAmount] = useState(selectedItems[index].amount)

    const _handleIdChange = (event) => {
        setSelectedItemId(event.target.value)
    }

    const _handleAmountChange = (event) => {
        setAmount(event.target.value)
    }

    let newSelectedItems = [...selectedItems]
    newSelectedItems[index] = { id: selectedItemId, amount }

    useEffect(() => {
        setSelectedItems(newSelectedItems)
    }, [index, selectedItemId, amount])

    return (
        <div className={"flex flex-row justify-between mt-3 " + className}>
            <div className="flex-3">
                <select className="block form-select py-3 px-4 w-full border border-gray-300 shadow-sm bg-white rounded" value={selectedItemId} onChange={_handleIdChange}>
                    {
                        options.map((option) => {
                            const { label = "", value = "" } = option
                            return (
                                <option key={value} value={value}>{label}</option>
                            )
                        })
                    }
                </select>
                
            </div>
            <div className="flex-1 ml-3">
                <input className="h-full w-full pr-3 text-right border shadow-sm rounded border-gray-300" inputMode="numeric" pattern="[0-9]*" type="number" min={1} max={100} value={amount} onChange={_handleAmountChange} />
            </div>
        </div>
    )
}