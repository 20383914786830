import React, { useState, useEffect, useContext } from 'react'
import { ItemSelectionCard, ItemSelection } from '../../components/ItemSelection';
import { WidgetContext } from '../../components/Widget';
import { Link } from 'react-router-dom';
import { Card } from '../../components/general/Card';
import { ItemTable } from '../../components/ItemTable';
import { getCentsToEurosString } from '../../utils';
import { OverlayContext } from '../../components/general/Overlay';

export const ItemSelectionPage = ({ onNextPage = () => { }, onSelectionChange = () => { } }) => {
    const { showLoading } = useContext(OverlayContext)
    const { event = {}, items = [], selectedItems = [], setSelectedItems = () => { }, totalPrice = 0 } = useContext(WidgetContext)
    const [showCalculation, setShowCalculation] = useState(false)
    const { id = "", name = "", date = "", location = "", checkout = {} } = event
    const { showRemailBox = false } = checkout

    if (selectedItems.length === 0 && items.length !== 0) {
        setSelectedItems([{ id: items[0].id, amount: 1 }])
    }

    const toggleShowCalculation = () => {
        setShowCalculation(!showCalculation)
    }

    return (
        <>
            <Card.Content>
                <h1 className="block font-semibold text-2xl" onClick={ showLoading }>
                    {name}
                </h1>
                <span className="block font-semibold text-lg text-gray-400"> {date} </span>
                <ItemSelection
                    className="mt-8"
                    handleSelectionChange={onSelectionChange}
                    totalPrice={totalPrice}
                />
                <span class="inline-block mt-4 text-sm text-gray-500">De transactiekosten zijn €0,50. Per ticket wordt er €0,50 servicekosten gerekend. <a className="block font-semibold text-blue-600 cursor-pointer" onClick={toggleShowCalculation}>Bekijk berekening.</a></span>
                { showCalculation && <ItemTable className="mt-4"/> }
            </Card.Content>

            
            <Link to="/details" className="block py-4 m-1 mt-4 rounded-lg bg-green-500 text-white text-center font-semibold">{getCentsToEurosString(totalPrice)} afrekenen &rarr;</Link>
        </>
    )
}
