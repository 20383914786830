import React, { useState, createContext, useContext, useEffect } from 'react'
import { RealTime } from '../realtime'
import { ShopScreen } from '../screens/ShopScreen'
import { WithFirebase, Authentication, AuthenticationContext } from '../firebase'
import { Card } from '../components/general/Card'
import { Router, Route, Switch, Redirect, BrowserRouter } from 'react-router-dom'
import { ItemSelectionPage } from '../pages/ItemSelectionPage'
import { DetailsPage } from '../pages/DetailsPage'
import { ThankYouPage } from '../pages/ThankYouPage'
import { OopsPage } from '../pages/OopsPage'
import { Calls } from '../calls'
import { ProfileDetailsPage } from '../pages/ProfileDetailsPage'
import { Queries } from '../queries'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { Validation } from '../validation'
import * as _ from 'lodash'
import { WithOverlay, OverlayContext } from '../components/general/Overlay'

export const WidgetContext = createContext({})

export const Widget = ({ eventId = "", itemId = "" }) => {

    const { showLoading, showSuccess, showFailure } = useContext(OverlayContext)
    const { uid = "" } = useContext(AuthenticationContext)
    const { data: event = {} } = RealTime.Event(eventId)
    const { data: items = [] } = RealTime.Items(eventId)
    const { data: itemStats = {} } = RealTime.ItemStats(eventId)
    const { data: userProfile = {}, isFetching } = Queries.GetUserProfile(uid)

    const _items = filterItems(items, itemId, itemId)

    const [selectedItems, setSelectedItems] = useState([])

    const [checkoutDetails, setCheckoutDetails] = useState({})

    const { checkout = {} } = event
    const { transactionFee = 0, serviceFee = 0, customFields = [] } = checkout

    const _getItem = (id = "") => {
        return items.find((item = {}) => {
            return item.id === id
        }) || {}
    }

    const totalAmountSelectedItems = selectedItems.reduce((total, { amount = 1 }) => {
        return parseInt(total) + parseInt(amount || 1)
    }, 0)

    const totalPrice = selectedItems.reduce((total, item) => {
        const { id = "", amount = 1 } = item
        const { price = 0 } = _getItem(id)
        return total += (parseInt(price) * parseInt(amount || 1))
    }, 0) + transactionFee + (totalAmountSelectedItems * serviceFee)

    const _requestCheckout = async ({ firstName, lastName, email, details, phoneNumber }) => {
        return Calls.BuyTickets(eventId, selectedItems, firstName, lastName, email, details, phoneNumber)
    }

    const updateSelectedItems = (index = 0, id = "", amount = 0) => {
        let newSelectedItems = [...selectedItems]
        newSelectedItems[index] = { id, amount }
        setSelectedItems(newSelectedItems)
    }

    const detailShape = customFields.reduce((shape, { field, required }) => {
        shape[field] = Yup.string()

        if (required) {
            shape[field] = shape[field].required('Dit veld is verplicht')
        }
        return shape
    }, {})

    const detailInitState = customFields.reduce((shape, { field }) => {
        shape[field] = ""
        return shape
    }, {})

    const detailSchema = Yup.object().shape({
        details: Yup.object().shape(detailShape)
    })

    const validationSchema = Validation.ProfileSchema.concat(detailSchema)

    return (
        <WithFirebase>
            <WidgetContext.Provider value={{
                event,
                items: _items,
                itemStats,
                selectedItems,
                setSelectedItems,
                updateSelectedItems,
                requestCheckout: _requestCheckout,
                checkoutDetails,
                setCheckoutDetails,
                totalAmountSelectedItems,
                totalPrice,
                userProfile,
                validationSchema
            }}>
                <BrowserRouter>
                    <Switch>
                        <Route exact path="/">
                            <Redirect to="/item-selection" />
                        </Route>
                        <Route exact path="/item-selection">
                            <ItemSelectionPage />
                        </Route>
                        <Route exact path="/details">
                            <Formik
                                initialValues={{
                                    firstName: "",
                                    lastName: "",
                                    email: "",
                                    details: detailInitState
                                }}
                                validationSchema={validationSchema}
                                onSubmit={(values, helpers) => {
                                    var windowReference; 

                                    showLoading()
                                    
                                    _requestCheckout(values).then(({ success, data }) => {
                                        if (success) {
                                            windowReference.location = data //(data, "_blank")
                                        } else {
                                            windowReference.close()
                                            window.onPaymentFailed()
                                        }
                                    })

                                    windowReference = window.open();

                                    window.onPaymentSucceeded = async () => {
                                        console.log("success")
                                        await showSuccess()
                                    }

                                    window.onPaymentFailed = async () => {
                                        console.log("fail")
                                        await showFailure()
                                    }

                                    window.addEventListener("message", (event) => {
                                        if (event.data === "payment-success"){
                                            window.onPaymentSucceeded()
                                        } else if (event.data === "payment-failure") {
                                            window.onPaymentFailed()
                                        }
                                    })
                                }}>
                                <Form>
                                    <Authentication.WhenLoggedIn>
                                        <ProfileDetailsPage />
                                    </Authentication.WhenLoggedIn>

                                    <Authentication.WhenLoggedOut>
                                        <DetailsPage />
                                    </Authentication.WhenLoggedOut>
                                </Form>
                            </Formik>
                        </Route>
                        <Route exact path="/profile-details">
                            <ProfileDetailsPage />
                        </Route>
                        <Route exact path="/success">
                            <ThankYouPage />
                        </Route>
                        <Route exact path="/failure">
                            <OopsPage />
                        </Route>
                        <Route exact path="/payment-success">
                            {() => {
                                try {
                                    window.opener.onPaymentSucceeded()
                                } catch(error) {
                                    window.opener.postMessage("payment-success", "*")
                                }
                                window.close()
                                return (<></>)
                            }}
                        </Route>
                        <Route exact path="/payment-failure">
                            {() => {
                                try {
                                    window.opener.onPaymentFailed()
                                } catch(error) {
                                    window.opener.postMessage("payment-failure", "*")
                                }
                                window.close()
                                return (<></>)
                            }}
                        </Route>
                    </Switch>
                </BrowserRouter>
            </WidgetContext.Provider >
        </WithFirebase >
    )
}

const filterItems = (items, itemStats = {}, itemId = "") => {
    return items.filter((item) => {

        const { id = "", rules = {}, isClosed = false } = item
        const _stats = itemStats[id] || {}

        const { startDate = false, endDate = false, max = false, singleOnly = false } = rules
        const { public: _public = {} } = _stats
        const { stats = {} } = _public
        const { sold = 0, reserved = 0 } = stats

        let result = true;

        if (max === 0 || isClosed === true) {
            return false
        }

        const now = new Date(Date.now());
        if (startDate) {
            result = result && startDate.toDate() < now
        }

        if (endDate) {
            result = result && endDate.toDate() > now
        }

        if (max && sold) {
            result = result && max > (sold + reserved)
        }

        if (itemId) {
            result = result && id === itemId
        } else if (singleOnly) {
            result = result && !singleOnly
        }

        return result
    })
}