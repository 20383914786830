import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { App } from './App';
// import Init from './components/Init';
import registerServiceWorker from './registerServiceWorker';

ReactDOM.render((
    // <Init>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    // </Init>
), document.getElementById("root"))
//registerServiceWorker();
