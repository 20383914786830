import React, { Component, useEffect, useState } from 'react';
import _ from 'lodash';
import { WithFirebase, Authentication } from './firebase';
import { Widget } from './components/Widget';
import { Card } from './components/general/Card';
import { WithOverlay } from './components/general/Overlay';

export const App = () => {
	return (
		<Authentication.WithAuthentication>
			<Card.Shell className="relative text-gray-900">
				<WithOverlay>
					<Widget eventId={"zdTeH6XByHHnpSsn5POs"} />
				</WithOverlay>
			</Card.Shell>
		</Authentication.WithAuthentication>
	);
}
