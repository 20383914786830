import * as Yup from 'yup'

export const Validation = {
    ProfileSchema: Yup.object().shape({
        firstName: Yup.string()
            .min(3, 'De voornaam moet langer zijn dan 2 tekens.')
            .max(49, 'De voornaam moet korter zijn dan 50 tekens.')
            .required('Een voornaam is verplicht.'),
        lastName: Yup.string()
            .min(2, 'De achternaam moet langer zijn dan 1 teken.')
            .max(49, 'De achternaam moet korter zijn dan 50 tekens.')
            .required('Een achternaam is verplicht.'),
        email: Yup.string()
            .email('E-Mailadres is niet geldig.')
            .required('Een e-mailadres is verplicht.')
    })
}