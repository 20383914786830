import React, { useEffect, useState } from 'react'
import { Firestore, Database } from '../firebase'

export const RealTime = {
    Event: (eventId) => {
        const [event, setEvent] = useState({})

        useEffect(() => {
            const listener = Firestore.collection("events").doc(eventId).onSnapshot((doc) => {
                setEvent({
                    ...doc.data(),
                    id: doc.id
                })
            })

            return listener
        }, [eventId])

        return { data: event }
    },
    Items: (eventId) => {
        const [items, setItems] = useState([])

        useEffect(() => {
            const listener = Firestore.collection("events").doc(eventId).collection("items").onSnapshot((snapshot) => {
                setItems(snapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        id: doc.id
                    }
                }))
            })

            return listener
        }, [eventId])

        return { data: items }
    },
    ItemStats: (eventId) => {
        const [stats, setStats] = useState({})

        useEffect(() => {
            const ref = Database.ref("/events/" + eventId + "/items/")
            ref.on("value", (items) => {
                setStats((items.val() || {}))
            })

            return () => {
                ref.off("value")
            }
        }, [eventId])

        return { data: stats }
    }
}