import React, { createContext } from 'react'
import { motion, useAnimation } from 'framer-motion'

export const OverlayContext = createContext({})

export const WithOverlay = ({ children }) => {

    const baseControls = useAnimation()
    const loadingControls = useAnimation()
    const successControls = useAnimation()
    const failureControls = useAnimation()
    const soldOutControls = useAnimation()

    const showLoading = async () => {
        hideAllContents()
        await showBase()
        await loadingControls.start({
            opacity: 1,
            y: 0,
            transition: {
                duration: 0.3,
                ease: "easeOut"
            }
        })
    }

    const showSuccess = async () => {
        hideAllContents()
        await showBase()
        await successControls.start({
            opacity: 1,
            y: 0,
            transition: {
                duration: 0.3,
                ease: "easeOut"
            }
        })
    }

    const showFailure = async () => {
        hideAllContents()
        await showBase()
        await failureControls.start({
            opacity: 1,
            y: 0,
            transition: {
                duration: 0.3,
                ease: "easeOut"
            }
        })
    }

    const hideAllContents = async (duration = 0.3) => {
        await Promise.all([
            loadingControls.start({
                opacity: 0,
                y: "40px",
                transition: {
                    duration
                }
            })
        ])
    }

    const showBase = async (duration = 0.3) => {
        return baseControls.start({
            opacity: 1,
            pointerEvents: "auto",
            transition: {
                duration
            }
        })
    }

    const hideBase = async (duration = 0.3) => {
        return baseControls.start({
            opacity: 0,
            pointerEvents: "none",
            transition: {
                duration
            }
        })
    }

    return (
        <OverlayContext.Provider value={{
            showLoading,
            showSuccess,
            showFailure
        }}>
            <motion.div animate={baseControls} style={{ opacity: 0, pointerEvents: "none" }} initial={false} className="absolute inset-0 bg-white">
                <motion.div animate={loadingControls} style={{ opacity: 0, y: "40px" }} initial={false} className="absolute inset-0 flex items-center justify-center">
                    <div className="sk-chase">
                        <div className="sk-chase-dot"></div>
                        <div className="sk-chase-dot"></div>
                        <div className="sk-chase-dot"></div>
                        <div className="sk-chase-dot"></div>
                        <div className="sk-chase-dot"></div>
                        <div className="sk-chase-dot"></div>
                    </div>
                </motion.div>
                <motion.div animate={successControls} style={{ opacity: 0, y: "40px" }} initial={false} className="absolute inset-0 flex items-center justify-center">
                    <svg class="h-6 w-6 text-green-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    Gelukt!
                </motion.div>
                <motion.div animate={failureControls} style={{ opacity: 0, y: "40px" }} initial={false} className="absolute inset-0 flex items-center justify-center">
                    Mislukt...
                </motion.div>
                <motion.div animate={soldOutControls} style={{ opacity: 0, y: "40px" }} initial={false} className="absolute inset-0 flex items-center justify-center">
                    Uitverkocht!
                </motion.div>
            </motion.div>
            {children}
        </OverlayContext.Provider>
    )
}