import { Analytics, Functions } from "../firebase";


export const Calls = {
    BuyTickets: async (eventId = "", selectedItems = [], firstName, lastName, email, details, phoneNumber) => {
        console.log(eventId, selectedItems, firstName, lastName, email)
        try {
            const result = await Functions.httpsCallable("createPayment")({
                event_id: eventId,
                items: selectedItems,
                firstName,
                lastName,
                name: firstName + " " + lastName,
                email,
                details,
                phoneNumber
            })
            
            return { success: true, data: result.data }
        } catch (error) {
            console.error(error);
            console.error(error.details);
            if (error.details && error.details.type === "max-exceeded") {
                const { details = {} } = error
                const { ordered = 0, left = 0, item = {} } = details
                const { name = "", id = "" } = item
                return { success: false, message: "Ticket (" + name + ") is nog maar " + left + " keer over." }
            }
            return { success: false, message: "Oeps! Daar ging iets mis. Probeer het nog een keer." }
        }
    }
}