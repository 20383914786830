import React, { useContext } from 'react'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import { getCentsToEurosString } from '../../utils';
import { SubmitButtonElement } from '../../components/FormElements/SubmitButtonElement';
import { WidgetContext } from '../../components/Widget';
import { TextElement } from '../../components/FormElements/TextElement';
import { Card } from '../../components/general/Card';
import { Link, Redirect } from 'react-router-dom';
import { AuthenticationContext } from '../../firebase';
import { OverlayContext } from '../../components/general/Overlay';

export const DetailsPage = ({ isLoading = false, onRequestCheckout = () => { }, onPreviousPage = () => { } }) => {

    const { showLoading } = useContext(OverlayContext)
    const { setCheckoutDetails, totalPrice, event = {} } = useContext(WidgetContext)
    const { name = "", date = "", checkout = {} } = event
    const { customFields = [] } = checkout

    const detailShape = customFields.reduce((shape, { field, required }) => {
        shape[field] = Yup.string()

        if (required) {
            shape[field] = shape[field].required('Dit veld is verplicht')
        }
        return shape
    }, {})


    let shape = {
        firstName: Yup.string()
            .min(3, 'De voornaam moet langer zijn dan 2 tekens.')
            .max(49, 'De voornaam moet korter zijn dan 50 tekens.')
            .required('Een voornaam is verplicht.'),
        lastName: Yup.string()
            .min(2, 'De achternaam moet langer zijn dan 1 teken.')
            .max(49, 'De achternaam moet korter zijn dan 50 tekens.')
            .required('Een achternaam is verplicht.'),
        email: Yup.string()
            .email('E-Mailadres is niet geldig.')
            .required('Een e-mailadres is verplicht.'),
        details: Yup.object().shape(detailShape)
    }

    const validationSchema = Yup.object().shape(shape);

    return (
        <>
            <Card.Content>
                <h1 className="block font-semibold text-2xl">
                    {name}
                </h1>
                <span className="block font-semibold text-lg text-gray-400"> {date} </span>
                <div className="flex flex-row mt-3">
                    <div className="flex-1">
                        <TextElement name="firstName" type="text" placeholder="Voornaam" />
                    </div>
                    <div className="ml-3 flex-1">
                        <TextElement name="lastName" type="text" placeholder="Achternaam" />
                    </div>
                </div>
                <div className="mt-3 mb-6">
                    <TextElement name="email" placeholder="E-Mailadres" />
                </div>
                {/* <TextElement name="phoneNumber" error={ props.errors.phoneNumber } label="Telefoonnummer" placeholder="Telefoonnummer" tooltip="Je telefoonnummer wordt gebruikt om je ticket ook per whatsapp te versturen." onChange={ props.handleChange } /> */}
                {
                    customFields.map((customField) => {
                        const { type = "", field = "", label = "", tooltip = "", placeholder = "" } = customField
                        if (type === "text") {
                            return (<TextElement key={field + "custom"} name={"details." + field} label={label} tooltip={tooltip} placeholder={placeholder} />)
                        }
                    })
                }
            </Card.Content>
            <div className="flex flex-row">
                <Link to="/item-selection" className="flex-1 block py-4 px-3 m-1 mt-0 rounded-lg text-blue-500 text-center text-xs font-medium">&larr; Terug</Link>
                <button type="submit" className="flex-3 block py-4 m-1 mt-0 rounded-lg bg-green-500 text-white text-center font-semibold">{getCentsToEurosString(totalPrice)} afrekenen &rarr;</button>
            </div>
        </>
    )
}