import React from 'react'

export const Card = {
    Shell: ({ className = "", children }) => {
        return (
            <div className={"bg-white overflow-hidden overflow-hidden shadow rounded-lg " + className}>
                {children}
            </div>
        )
    },
    Content: ({ className = "", children }) => {
        return (
            <div className={"px-4 py-5 sm:p-6 " + className}>
                {children}
            </div>
        )
    },
    Footer: ({ className = "", children }) => {
        return (
            <div className={" px-4 py-4 sm:px-6 " + className}> 
                {children}
            </div>
        )
    },
    Default: ({ className = "", children }) => {
        return (
            <Card.Shell className={className}>
                <Card.Content>
                    {children}
                </Card.Content>
            </Card.Shell>
        )
    },
    Well: ({ className = "", children }) => {
        return (
            <div className={"bg-gray-200 overflow-hidden rounded-lg " + className}>
                <div className="px-4 py-5 sm:p-6">
                    { children }
                </div>
            </div>
        )
    }
}