import React, { useContext } from 'react'
import { getCentsToEurosString } from '../../utils'
import { WidgetContext } from '../Widget'

export const ItemTable = ({ className = "" }) => {

    const { event = {}, items = [], selectedItems = [], totalPrice = 0, totalAmountSelectedItems = 0 } = useContext(WidgetContext)
    const { checkout = {} } = event
    const { transactionFee = 0, serviceFee = 0 } = checkout

    const getItem = (id = "") => {
        return items.find((item = {}) => {
            return item.id === id
        }) || {}
    }

    return (
        <div className={"rounded-lg border text-sm overflow-hidden " + className}>
            <table className="min-w-full">
                {/* <thead>
                    <tr>
                        <th className="px-4 py-2 text-left">Ticket</th>
                        <th className="px-4 py-2 text-left">Prijs</th>
                        <th className="px-4 py-2 text-left">Totaal</th>
                    </tr>
                </thead> */}
                <tbody>
                    {
                        selectedItems.map((item) => {
                            const { id = "", amount = 1 } = item

                            return (
                                <tr key={id}>
                                    <td className="px-4 py-1 pt-2 text-left">{amount + "x " + getItem(id).name}</td>
                                    {/* <td className="px-4 py-1 pt-2 text-left">{getCentsToEurosString(getItem(id).price)}</td> */}

                                    <td className="px-4 py-1 pt-2 text-left font-bold">
                                        {
                                            getCentsToEurosString(getItem(id).price * amount)
                                        }
                                    </td>
                                </tr>
                            )
                        })
                    }
                    {serviceFee > 0 && <tr key="serviceFee">
                        <td className="px-4 py-1 text-left">{totalAmountSelectedItems}x Servicekosten</td>
                        {/* <td className="px-4 py-1 text-left">{getCentsToEurosString(serviceFee)}</td> */}

                        <td className="px-4 py-1 text-left font-bold">
                            {
                                getCentsToEurosString(totalAmountSelectedItems * serviceFee)
                            }
                        </td>
                    </tr>}
                    {transactionFee > 0 && <tr key="transFee">
                        <td className="px-4 py-1 pb-2 text-left">Transactiekosten</td>
                        {/* <td className="px-4 py-1 pb-2 text-left">{getCentsToEurosString(transactionFee)}</td> */}

                        <td className="px-4 py-1 pb-2 text-left font-bold">
                            {
                                getCentsToEurosString(transactionFee)
                            }
                        </td>
                    </tr>}
                    {
                        // <tr className="m-1 rounded border-t">
                        //     <td className="px-4 py-2 text-left"></td>
                        //     <td className="px-4 py-2 text-left">Totaal:</td>
                        //     <td className="px-4 py-2 text-left font-bold">
                        //         <b>{getCentsToEurosString(totalPrice)}</b>
                        //     </td>
                        // </tr>
                    }
                </tbody>
            </table>
        </div>
    )
}