import React, { useContext, useEffect } from 'react'
import * as Yup from 'yup'
import { Formik, Form, useFormikContext } from 'formik'
import { getCentsToEurosString } from '../../utils';
import { SubmitButtonElement } from '../../components/FormElements/SubmitButtonElement';
import { WidgetContext } from '../../components/Widget';
import { TextElement } from '../../components/FormElements/TextElement';
import { Card } from '../../components/general/Card';
import { Link, Redirect } from 'react-router-dom';
import { AuthenticationContext, Auth } from '../../firebase';
import { Validation } from '../../validation'

export const ProfileDetailsPage = ({ isLoading = false, onRequestCheckout = () => { }, onPreviousPage = () => { } }) => {

    const { setCheckoutDetails, totalPrice, event = {}, userProfile = {} } = useContext(WidgetContext)
    const { values, setFieldValue } = useFormikContext()
    const { name = "", date = "", checkout = {} } = event
    const { customFields = [] } = checkout
    const { firstName = "", lastName = "", email = "" } = userProfile

    const signOut = async () => {
        Auth.signOut()
    }

    useEffect(() => {
        setFieldValue("firstName", firstName)
        setFieldValue("lastName", lastName)
        setFieldValue("email", email)
    }, [firstName, lastName, email])

    return (
        <>
            <Card.Content>
                <h1 className="block font-semibold text-2xl">
                    {name}
                </h1>
                <span className="block font-semibold text-lg text-gray-400"> {date} </span>
                <div className="mt-6 py-2 px-4 rounded-lg bg-blue-50 ">
                    <span className="block font-semibold">{firstName + " " + lastName}</span>
                    <span className="block text-gray-600">{email}</span>
                </div>
                <span className="block mb-2 py-2 px-4 text-xs text-right font-semibold text-blue-500 cursor-pointer" onClick={signOut}>uitloggen</span>
                {/* <TextElement name="phoneNumber" error={ props.errors.phoneNumber } label="Telefoonnummer" placeholder="Telefoonnummer" tooltip="Je telefoonnummer wordt gebruikt om je ticket ook per whatsapp te versturen." onChange={ props.handleChange } /> */}
                {
                    customFields.map((customField) => {
                        const { type = "", field = "", label = "", tooltip = "", placeholder = "" } = customField
                        if (type === "text") {
                            return (<TextElement key={field + "custom"} name={"details." + field} label={label} tooltip={tooltip} placeholder={placeholder} />)
                        }
                    })
                }
            </Card.Content>
            <div className="flex flex-row">
                <Link to="/item-selection" className="flex-1 block py-4 px-3 m-1 mt-0 rounded-lg text-blue-500 text-center text-xs font-medium">&larr; Terug</Link>
                <button type="submit" className="flex-3 block py-4 m-1 mt-0 rounded-lg bg-green-500 text-white text-center font-semibold cursor-pointer">{getCentsToEurosString(totalPrice)} afrekenen &rarr;</button>
            </div>
        </>
    )
}